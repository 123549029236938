<style>
.label {
  color: inherit !important;
}
.help {
  margin-bottom: 0px !important;
}
</style>
<template>
  <div>
    <div
      v-bind:class="darkMode"
      class="card p-4 mb-3"
    >
      <div class="row mb-1">
        <div class="col">
          <b-field
            :type="isDanger[0] ? 'is-danger' : ''"
            :message="errorMessage[0]"
            label="New Password"
          >
            <b-input
              type="password"
              v-model="newPassword"
              value=""
              password-reveal
            ></b-input>
          </b-field>

          <b-field
            :type="isDanger[1] ? 'is-danger' : ''"
            :message="errorMessage[1]"
            label="Repeat Password"
          >
            <b-input
              type="password"
              v-model="repeatPassword"
              value=""
            ></b-input>
          </b-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col d-flex justify-content-end">
        <button
          v-bind:class="darkModeBtn"
          v-on:click="changePassword()"
          class="btn"
        >Change Password</button>
      </div>
    </div>
  </div>
</template>
<script>
import { darkModeStyle } from '@assets/js/darkMode';
import { ContentLoader } from "vue-content-loader"
import firebaseErrors from '@assets/js/firebaseErrors';

export default {
  name: 'CardForm',
  components: {
    ContentLoader
  },
  data() {
    return {
      newPassword: '',
      repeatPassword: '',
      isDanger: [false, false],
      errorMessage: ['', ''],
      darkMode: darkModeStyle(),
      darkModeBtn: darkModeStyle('btn-dark', 'btn-light'),
    }
  },
  watch: {
    newPassword() {
      if (this.newPassword.length < 8) {
        this.isDanger[0] = true;
        this.errorMessage[0] = 'Password must be at least 8 characters long';
      } else {
        this.isDanger[0] = false;
        this.errorMessage[0] = '';
      }
    },
    repeatPassword() {
      if (this.newPassword !== this.repeatPassword) {
        this.isDanger[1] = true
        this.errorMessage[1] = 'Passwords do not match'
      } else {
        this.isDanger[1] = false
        this.errorMessage[1] = ''
      }
    },
  },
  methods: {
    changePassword() {

      if (this.newPassword.length < 8) {

        this.$buefy.toast.open({
          duration: 4000,
          message: 'Password must be at least 8 characters long.',
          type: 'is-danger',
          position: 'is-bottom',
          actionText: 'Close',
          queue: true,
        });

        return;
      }

      if (this.newPassword !== this.repeatPassword) {

        this.$buefy.toast.open({
          duration: 4000,
          message: 'Passwords do not match.',
          type: 'is-danger',
          position: 'is-bottom',
          actionText: 'Close',
          queue: true,
        });

        return;
      }

      this.$buefy.dialog.prompt({
        message: `What's your current Password?`,
        confirmText: 'Change Password',
        inputAttrs: {
          type: 'password'
        },
        trapFocus: true,
        closeOnConfirm: false,
        onConfirm: (password, { close }) => {

          this.$store.dispatch('changePassword', { oldPassword: password, newPassword: this.newPassword })
            .then(() => {

              this.$buefy.toast.open({
                duration: 4000,
                message: 'Password changed successfully.',
                type: 'is-success',
                position: 'is-bottom',
                actionText: 'Close',
                queue: true,
              });

              close();
              this.$router.push('/account');

            }).catch(this.passwordError);

        },
        onCancel: () => {
          this.$buefy.toast.open({
            duration: 4000,
            message: 'Password change cancelled.',
            type: 'is-info',
            position: 'is-bottom',
            actionText: 'Close',
            queue: true,
          });

          this.newPassword = '';
          this.repeatPassword = '';
        }
      })
    },
    passwordError(error) {
      const errorMessage = firebaseErrors[error.code] ? firebaseErrors[error.code] : error.message;

      this.$buefy.toast.open({
        duration: 4000,
        message: `${errorMessage}`,
        type: 'is-danger',
        position: 'is-top',
        actionText: 'Close',
        queue: true,
      });
    },
  }
}
</script>